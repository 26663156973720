<template>
  <div class="md:flex mb-10 pb-10 border-b border-gray flex-wrap">
    <div class="md:mr-12 xl:mr-24">
      <div class="flex mb-4 items-center">
        <label for="rated_voltage" class="w-40 text-lg block mr-2">
          {{ labelRatedVoltage }}
        </label>
        <div>
          <input type="number" step="0.01" id="rated_voltage" v-model="rated_voltage" placeholder="20" class="border border-gray h-12 p-2 leading-none w-36">
        </div>
      </div>

      <div class="flex mb-4 items-center">
        <label for="rated_current" class="w-40 text-lg block mr-2">
          {{ labelRatedCurrent }}
        </label>
        <div>
          <input type="number" step="0.01" id="rated_current" v-model="rated_current" placeholder="20" class="border border-gray h-12 p-2 leading-none w-36">
        </div>
      </div>

      <div class="flex mb-12 lg:mb-0 items-center">
        <label for="manufacturer_year" class="w-40 text-lg block mr-2">
          {{ labelManufactureYear }}
        </label>
        <div>
          <input type="number" step="0.01" id="manufacturer_year" v-model="manufacturer_year" placeholder="1999" class="border border-gray h-12 p-2 leading-none w-36">
        </div>
      </div>
    </div>

    <div class="lg:mr-12 xl:mr-24">
      <p class="text-lg block mb-4">{{ headingOperatingValues }}</p>

      <div class="flex mb-4 items-center">
        <label for="voltage" class="w-40 text-lg block mr-2">
          {{ labelVoltage }}
        </label>
        <div>
          <input type="number" step="0.01" id="voltage" v-model="voltage" placeholder="20" class="border border-gray h-12 p-2 leading-none w-36">
        </div>
      </div>

      <div class="flex mb-12 lg:mb-0 items-center">
        <label for="amps" class="w-40 text-lg block mr-2">
          {{ labelAmps }}
        </label>
        <div>
          <input type="number" step="0.01" id="amps" v-model="amps" placeholder="20" class="border border-gray h-12 p-2 leading-none w-36">
        </div>
      </div>
    </div>

    <div>
      <p class="text-lg block mb-4">Operating regime:</p>

      <div class="flex mb-4 items-center">
        <label for="hours" class="w-40 text-lg block mr-2">
          Hours/day:
        </label>
        <div>
          <input type="number" step="1" id="hours" v-model="hours" placeholder="0" class="border border-gray h-12 p-2 leading-none w-36">
        </div>
      </div>

      <div class="flex items-center">
        <label for="days" class="w-40 text-lg block mr-2">
          Days/year:
        </label>
        <div>
          <input type="number" step="1" id="days" v-model="days" placeholder="0" class="border border-gray h-12 p-2 leading-none w-36">
        </div>
      </div>
    </div>
  </div>

  <div class="md:flex mb-10">
    <div class="mb-4 md:mb-0 result md:mr-10">
      <div class="text-lg block mb-4 font-medium">{{ labelResultEfficiency }}</div>
      <div class="bg-gray-100 font-bold text-center border border-gray h-12 p-2 leading-8 w-36">{{ efficiency }}</div>
    </div>

    <div class="result">
      <div class="text-lg block mb-4 font-medium">{{ labelResultSavings }}</div>
      <div class="bg-gray-100 font-bold text-center border border-gray h-12 p-2 leading-8 w-36">{{ saving }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'labelRatedVoltage',
    'labelRatedCurrent',
    'labelManufactureYear',
    'headingOperatingValues',
    'labelVoltage',
    'labelAmps',
    'labelResultEfficiency',
    'labelResultSavings'
  ],
  data() {
    return {
      rated_voltage: '',
      rated_current: '',
      manufacturer_year: '',
      voltage: '',
      amps: '',
      hours: '',
      days: '',
      thyristor: {
        1: 88.00,
        0.9526: 86.00,
        0.9053: 85.00,
        0.8579: 84.00,
        0.8105: 83.00,
        0.7632: 82.00,
        0.7158: 81.80,
        0.6684: 81.60,
        0.6211: 81.40,
        0.5737: 81.20,
        0.5263: 81.00,
        0.4789: 77.30,
        0.4316: 77.00,
        0.3842: 73.40,
        0.3368: 69.80,
        0.2895: 66.20,
        0.2421: 63.00,
        0.1947: 56.00,
        0.1474: 49.00,
        0.1000: 42.00
      },
      flexKraft: {
        1.0000: 91.00,
        0.9526: 90.50,
        0.9053: 90.00,
        0.8579: 89.50,
        0.8105: 89.00,
        0.7632: 88.00,
        0.7158: 87.33,
        0.6684: 86.67,
        0.6211: 86.00,
        0.5737: 85.00,
        0.5263: 84.00,
        0.4789: 83.00,
        0.4316: 82.00,
        0.3842: 80.00,
        0.3368: 78.00,
        0.2895: 76.00,
        0.2421: 74.00,
        0.1947: 72.00,
        0.1474: 71.50,
        0.1000: 70.00
      }
    }
  },
  computed: {
    efficiency() {
      if (this.rated_voltage !== '' && this.rated_current !== '' && this.voltage !== '' && this.amps !== '') {
        let percentage = this.voltage / this.rated_voltage;
        let thyristorVal = this.closest(percentage, Object.keys(this.thyristor));
        return this.thyristor[thyristorVal] + '%';
      }
      return 0;
    },
    saving() {
      if (this.rated_voltage !== '' && this.rated_current !== '' && this.voltage !== '' && this.amps !== '' && this.hours !== '' && this.days !== '') {
        let percentage = this.voltage / this.rated_voltage;
        let thyristorVal = this.closest(percentage, Object.keys(this.thyristor));
        let flexKraftVal = this.closest(percentage, Object.keys(this.flexKraft));
        let poutWatt = this.voltage * this.amps;
        let thyristorPinWatt = poutWatt / (this.thyristor[thyristorVal] / 100);
        let thyristorPowerLossWatt = thyristorPinWatt - poutWatt;
        let flexKraftPinWatt = poutWatt / (this.flexKraft[flexKraftVal] / 100);
        let flexKraftPowerLossWatt = flexKraftPinWatt - poutWatt;
        let wasteWatt = thyristorPowerLossWatt - flexKraftPowerLossWatt;
        return ((wasteWatt * this.hours * this.days) / 1000).toFixed(2);
      }
      return 0;
    }
  },
  methods: {
    closest(needle, haystack) {
      return haystack.reduce((a, b) => {
        let aDiff = Math.abs(a - needle);
        let bDiff = Math.abs(b - needle);

        if (aDiff === bDiff) {
          return a > b ? a : b;
        } else {
          return bDiff < aDiff ? b : a;
        }
      });
    }
  }
};
</script>

<style>

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

</style>
