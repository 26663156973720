<template>
  <div class="heading">
    <h3>{{ title }}</h3>
    <p>{{ subtitle }}</p>
    <h4>{{ intro }}</h4>
  </div>
  <Calculator
      :label-rated-voltage="labelRatedVoltage"
      :label-rated-current="labelRatedCurrent"
      :label-manufacture-year="labelManufactureYear"
      :heading-operating-values="headingOperatingValues"
      :label-voltage="labelVoltage"
      :label-amps="labelAmps"
      :label-result-efficiency="labelResultEfficiency"
      :label-result-savings="labelResultSavings"
  ></Calculator>

  <p class="text-sm mb-2">* Based on our algorithm</p>
  <p class="text-sm">**Rectifier efficiency is defined as the ratio of DC power to the applied input AC power. For example, if a rectifier with 50% efficiency is required to provide a 50W power to a load, it will draw 100 W from the mains supply.</p>
</template>

<script>
import Calculator from "./components/Calculator";

export default {
  name: 'App',
  components: {
    Calculator
  },
  props: {
    title: {
      type: String,
      default: "Discover your savings potential",
    },
    subtitle: {
      type: String,
      default: "Enter your rectifier information",
    },
    intro: {
      type: String,
      default: "Rectifier information",
    },
    labelRatedVoltage: {
      type: String,
      default: "Rated voltage:",
    },
    labelRatedCurrent: {
      type: String,
      default: "Rated current:",
    },
    labelManufactureYear: {
      type: String,
      default: "Year of manufacture:",
    },
    headingOperatingValues: {
      type: String,
      default: "Typical operation",
    },
    labelVoltage: {
      type: String,
      default: "Voltage (Volts DC):",
    },
    labelAmps: {
      type: String,
      default: "Current (Amps):",
    },
    labelResultEfficiency: {
      type: String,
      default: "Your estimated power supply efficiency is*:",
    },
    labelResultSavings: {
      type: String,
      default: "Your potential energy savings per year, when you use KraftPowercon FlexKraft switch mode rectifiers**:",
    }
  }
}
</script>

<style>
#app {
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #000000;
}

.heading h3 {
  font-size: 46px;
  line-height: 44px;
  margin-bottom: 50px;
}
.heading p {
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 50px;
}
.heading h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 35px;
}
</style>
